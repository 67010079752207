import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01f01e89"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ss-corpo-resultado" }
const _hoisted_2 = {
  class: "ant-row",
  style: {"min-height":"50px"}
}
const _hoisted_3 = {
  key: 0,
  class: "ant-col ss-titulo-resultado"
}
const _hoisted_4 = { class: "ant-col ss-titulo-resultado" }
const _hoisted_5 = {
  class: "ant-col ant-col-xs-14 ant-col-xl-14 ss-valores",
  style: {"overflow-x":"auto","max-width":"650px","display":"flex"}
}
const _hoisted_6 = {
  class: "ant-row",
  style: {"text-align":"right"}
}
const _hoisted_7 = { style: {"text-align":"right"} }
const _hoisted_8 = {
  class: "ant-row",
  style: {"text-align":"right"}
}
const _hoisted_9 = { style: {"text-align":"right"} }
const _hoisted_10 = {
  key: 1,
  class: "ant-row"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "ant-col ss-botao-expandir" }
const _hoisted_14 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass('ant-col ant-col-xl-1 ss-tipo-resultado ' + _ctx.obterClasseGrupo()),
        style: {"flex":"0 0 30px"}
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.obterSimboloGrupo()), 1)
      ], 2),
      (_ctx.props.grupo.resultado === undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, [
              _createTextVNode(_toDisplayString(_ctx.props.grupo.descricao), 1),
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editarGrupo && _ctx.editarGrupo(...args)))
              }, [
                _createVNode(_component_icone, { nome: "editar" })
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString(_ctx.props.grupo.resultado.descricao), 1),
                _createElementVNode("a", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editarGrupo && _ctx.editarGrupo(...args)))
                }, [
                  _createVNode(_component_icone, { nome: "editar" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              (!_ctx.state.expandir)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.grupo.resultado.periodos, (periodo, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      style: {"text-align":"right","width":"100px","min-width":"100px","margin-left":"2px"}
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.obterDescricaoPeriodo(_ctx.grupo.resultado.periodos[index])), 1)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.UtilitarioMascara.mascararValor(_ctx.grupo.resultado.totais[index], 2)), 1)
                      ])
                    ]))
                  }), 128))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_a_table, {
                      columns: _ctx.props.colunasGrande.filter(item => item.visible),
                      "data-source": _ctx.props.grupo.resultado.linhas,
                      rowKey: "descricao",
                      pagination: false,
                      scroll: { y: 200, x: _ctx.state.windowWidth},
                      size: "small",
                      rowClassName: "ss-table-campos"
                    }, {
                      emptyText: _withCtx(() => [
                        _createVNode(_component_mensagem_sem_dados, { mensagem: "Nenhum item adicionado ainda" })
                      ]),
                      bodyCell: _withCtx(({ column, record, text }) => [
                        (column.key === 'Descricao')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(record.descricao), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.UtilitarioMascara.mascararValor(text,2)), 1))
                      ]),
                      _: 1
                    }, 8, ["columns", "data-source", "scroll"])
                  ]))
            ])
          ], 64)),
      _createElementVNode("div", _hoisted_13, [
        (_ctx.props.grupo.resultado !== undefined && _ctx.props.grupo.tipo !== _ctx.ETipoGrupoDRE.Totalizador)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.expandir && _ctx.expandir(...args))),
              name: _ctx.state.expandir ? 'Contrair' : 'Expandir'
            }, [
              _createVNode(_component_icone, {
                style: {"font-size":"24px"},
                nome: _ctx.state.expandir ? 'subir' : 'descer'
              }, null, 8, ["nome"])
            ], 8, _hoisted_14))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}